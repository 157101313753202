<template>
    <div class="contract-bargain-pay">
        <div class="bargain-pay-bread">
            <a-row>
                <a-col :span="3">
                    交定金
                </a-col>
                <a-col :span="18">
                </a-col>
                <a-col :span="3" style="text-align: right">
                    <a-button type="primary">提交</a-button>
                    <a-button style="margin-left: 10px">返回</a-button>
                </a-col>
            </a-row>
        </div>
        <div style="height: 1px ;background-color: lightgrey;"></div>
        <div class="contract-title-row content-item-block">
            <div class="title-text">七田真 深圳百花 定金凭证 <span></span></div>
        </div>

        <div class="page-search-form">
            <a-row>
                <a-col :span="16">
                    <a-form layout="inline" :form="searchForm">
                        <a-form-item layout="inline" label="交费地点">
                            <a-cascader
                                v-model="searchForm.center"
                                :options="centers"
                                :default-value="['1', '11', '111']"
                                style="width:180px"
                            >
                            </a-cascader>
                        </a-form-item>
                    </a-form>
                </a-col>
                <a-col :span="7" style="text-align: right">凭证号：7984844</a-col>
                <a-col :span="1"></a-col>
            </a-row>
        </div>


        <div class="contract-ticket-canvas">
            <a-row class="pay-info-row">
                <a-col :span="2" style="text-align: center"> </a-col>
                <a-col :span="2" style="text-align: left">学员姓名：汪乐</a-col>
                <a-col :span="7" style="text-align: center"></a-col>
                <a-col :span="4" style="text-align: center"> 课程名称：基础能力课</a-col>
                <a-col :span="9" style="text-align: center"></a-col>
            </a-row>
            <a-row class="pay-type-row">
                <a-col :span="2" > </a-col>
                <a-col :span="8" style="text-align: left;">交费方式：
                    <a-select default-value="y" v-model="searchForm.payType" style="width: 150px;">
                        <a-select-option :value="item.value" v-for="(item,index) in refundOptions"
                                         :key="index">
                            {{ item.label }}
                        </a-select-option>
                    </a-select>
                </a-col>
                <a-col :span="14" style="text-align: center;border-left: 1px solid lightgrey">交费金额: &nbsp;&nbsp;&nbsp;&nbsp;
                    <a-input v-model="searchForm.payAmount"  placeholder="请输入交费金额" style="width:150px " />
                </a-col>
            </a-row>
            <a-row class="pay-desc-row"  style="">
                <a-col :span="2" > </a-col>
                <a-col :span="8" style="text-align: left">交费说明</a-col>
                <a-col :span="14" class="pay-remark-row">
                    <a-textarea class="pay-remark-input" v-model="searchForm.remark"
                                placeholder="请输入交费说明"
                                :auto-size="{ minRows: 2, maxRows: 2 }"
                    />
                </a-col>
            </a-row>
        </div>

        <div class="pay-operator-row">
            <a-col :span="4" style="text-align: left">经办人
                <a-select default-value="y" v-model="searchForm.operator" style="width: 150px;">
                    <a-select-option :value="item.value" v-for="(item,index) in operators"
                                     :key="index">
                        {{ item.name }}
                    </a-select-option>
                </a-select>
            </a-col>
            <a-col :span="8" style="text-align: left">出纳
                <a-select default-value="y" v-model="searchForm.operator" style="width: 150px;">
                    <a-select-option :value="item.value" v-for="(item,index) in operators"
                                     :key="index">
                        {{ item.name }}
                    </a-select-option>
                </a-select>
            </a-col>
            <a-col :span="12" style="text-align: right">
                交费日期
                <a-date-picker show-time v-model="searchForm.payDate"></a-date-picker>
            </a-col>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ContactBargainPay',
    data() {
        return {
            showChangeGuardian: false,
            showChangeAddr: false,
            searchForm: {},
            contractStateOptions: [
                {
                    label: '是',
                    value: "y"
                },
                {
                    label: '否',
                    value: "n"
                },
            ],
            centers: [
                {
                    value: '1',
                    label: '华南',
                    children: [
                        {
                            value: '11',
                            label: '广东',
                            children: [
                                {
                                    value: '111',
                                    label: '深圳百花',
                                },
                            ],
                        },
                    ],
                },
            ],
            refundOptions:[
                {
                    value: '1',
                    label: '微信',
                }
            ],
            operators:[
                {
                    name: '张三',
                }
            ]

        }
    },
    created() {
    },
    mounted() {

    },
    methods: {}
}
</script>
<style scoped lang="less">
.contract-bargain-pay {
    padding: 0 30px;
    background-color: white;
    box-sizing: border-box;
    padding-bottom: 120px;
    height: 100%;

    .bargain-pay-bread {
        background-color: white;
        padding: 30px 0   20px 30px;
        font-size: 16px;
        font-weight: bold;
    }

    .contract-title-row {
        text-align: center;
        background-color: #fff;
        padding-top: 30px;
        padding-bottom: 30px;
        font-size: 20px;
        font-weight: bold;

    }

    .page-search-form {

    }

    .contract-ticket-canvas{
        margin-top: 20px;
        border:1px solid lightgrey;
        .pay-info-row{
            line-height: 60px;
            border-bottom: 1px solid lightgrey;
        }
        .pay-type-row{
            line-height: 80px;
        }
        .pay-desc-row{
            height: 80px;
            border-top: 1px solid lightgrey;
            line-height: 80px;
            .pay-remark-row{
                text-align: center;
                border-left: 1px solid lightgrey;
                padding-bottom: 20px;
                height: 80px;
                box-sizing: border-box;
                .pay-remark-input{
                    position: relative;
                    top: -12px;
                    width: 90%;
                }
            }
        }
    }

    .pay-operator-row{
        margin-top: 15px;
    }



}


</style>
